import {createRouter, createWebHistory} from 'vue-router'
// import store from '../store/index.js'


const routerOptions = [
    {
        name: 'Home',
        path: '/',
        component: 'HelloWorld',
        meta: {title: 'sh - photography'},
        // eslint-disable-next-line no-unused-vars
        redirect: to => ({ path: '/ComingSoon' })
    },
    {
        name: 'ComingSoon',
        path: '/ComingSoon',
        component: 'PageUnderConstruction',
        meta: {title: 'sh - photography'}
    },
    {
        path: "/:catchAll(.*)",
        redirect: '/ComingSoon',
        meta: {title: 'sh - asdf'}
    }
]


const routes = routerOptions.map((route) => {
    return {
        ...route,
        component: () => import(`../views/${route.component}.vue`)
    }
})

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach((to, from, next) => {
    const title = to.meta.title || 'sh - photography'; // Fallback title
    document.title = title;
    next();
});

// router.beforeEach((to, from, next) => {
//     if (to.matched.some(record => record.meta.requiresAuth) && !store.getters.isAuthenticated) {
//         next('/');
//     } else {
//         next();
//     }
// });

export default router;

