import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";

// Create store
const store = new Vuex.Store({
  state: {
    backgroundImage: "", // Standard-Hintergrundbild oder leer lassen
  },
  mutations: {
    setBackgroundImage(state, imagePath) {
      state.backgroundImage = imagePath;
    },
  },
  plugins: [createPersistedState()]
});

export default store;
