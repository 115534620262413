<template>
  <v-app >
    <div
      id="background"
      :style="{ backgroundImage: `url(${backgroundImage})`}">
      <Header />
        <v-main>
          <v-container fluid class="ma-0 pa-0" style="overflow-y: hidden">
            <!-- If using vue-router -->
            <router-view></router-view>
          </v-container>
        </v-main>
      <Footer />
    </div>
  </v-app>
</template>

<script>

import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { mapState } from "vuex";

export default {
  components: {
    'Header': Header,
    'Footer': Footer
  },
  computed: {
    ...mapState(["backgroundImage"])
  }
}
</script>

<style>
@font-face {
  font-family: "Funnel Sans Regular";
  src: url("./assets/fonts/Funnel_Sans/static/FunnelSans-Regular.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "Amatic SC Regular";
  src: url("./assets/fonts/Amatic_SC,Indie_Flower/Amatic_SC/AmaticSC-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Great Vibes Regular";
  src: url("./assets/fonts/Great_Vibes/GreatVibes-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Raleway Black";
  src: url("./assets/fonts/Raleway/Raleway-Black.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Raleway Regular";
  src: url("./assets/fonts/Raleway/Raleway-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Cormorant Regular";
  src: url("./assets/fonts/Cormorant/static/Cormorant-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Scope One Regular";
  src: url("./assets/fonts/Scope_One/ScopeOne-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Bodoni Moda";
  src: url("./assets/fonts/Bodoni_Moda/BodoniModa-VariableFont_opsz,wght.ttf") format("truetype");
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
