<template>
  <div>
    <div id="toolbar">
      <v-app-bar color="transparent" dense flat class="pa-0 ma-0">
        <div id="logo">silashirschi.photography</div>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn>
            <v-icon @click="openUrl">
              <img :src="instagramPath()" alt="instagram" />
            </v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-app-bar>
    </div>
  </div>
</template>

<script>

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Header",
  props: {
    color: String,
  },
  components: {

  },
  data: () => ({
    icons: {

    },
    overlay: false,
    pages: [
      {name: "Startseite", path: "/", loginNecessary: false},
    ]
  }),
  methods: {
    instagramPath() {
      return require("../assets/social-media/Instagram_Glyph_White.svg");
    },
    openUrl() {
      const url = 'https://www.instagram.com/silashirschi.photography/';
      window.open(url, '_blank');
    }
  }
}
</script>

<style>
#toolbar {
  position: absolute; /* Header wird über dem Hintergrundbild positioniert */
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}
#logo{
  font-family: Great Vibes Regular;
  font-size: xxx-large;
  margin: 0 0 0 10px;
}
</style>
